import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import AuthRoot from "./AuthRoot";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(109, 187, 191, 1)",
    },
  },
});

const App: React.FC<{}> = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AuthRoot />
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
